import { useEffect } from "react";
import domainName from "..";

function Redirect () {

    useEffect(() => {
        window.location.href = domainName + '/vulkan24_news';
    }, [])

    
}

export default Redirect;