import MainPage from "./pages/MainPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Redirect from "./pages/Redirect";
function App() {

  
  return (

    <BrowserRouter>
          <Routes>
            <Route path="/vulkan24_news" element={<MainPage/>} />
            <Route path='/' element={<Redirect/>}/>
            
          </Routes>
    </BrowserRouter>

  );
}

export default App;
