import { useEffect } from "react";
import '../css/main_page.css'
import chanelImage from '../img/logo.png'
import {isMobile} from 'react-device-detect';

function MainPage () {


    useEffect(() => {
        // Проверка, если ссылка должна быть открыта в Telegram
        const url = 'tg://resolve?domain=vulkan24_news';
        window.location.href = url;
      }, []);

    const viewInTelegram = () => {
        const url = 'tg://resolve?domain=vulkan24_news';
        window.location.href = url;
    }

    const openInWeb = () => {
        const url = 'https://web.telegram.org/k/#@vulkan24_news';
        window.location.href = url;
    }

    return (
        <div className="main-page">
            <div className="telegram-container">
                <div className="chanel-image-container">
                    <img className="chanel-image" src={chanelImage} alt='error'/>
                </div>
                <div className="chanel-title">Вулкан Игровые автоматы 24</div>
                <div className="subs-counter">5 694 subscribers</div>
                <div className="chanel-description">
                    
                    ✅ Официальный канал проекта Казино Вулкан игровые автоматы / Vulkan 24 теперь в телеграме
                    <br/><br/>
                    🔥 Делимся новостями, анонсируем турниры и релизы, раздаем уникальные бонусы!<br/><br/>

                    Бот - https://t.me/Oasis_Palmbot
                    
                </div>
                <div className="view-in-telegram-button">
                    <div className="view-in-telegram" onClick={viewInTelegram}>VIEW IN TELEGRAM</div>
                </div>
                {!isMobile && <div className="open-web-button">
                    <div className="open-web" onClick={openInWeb}>OPEN IN WEB</div>
                </div>}
            </div>
        </div>
    )
}

export default MainPage;